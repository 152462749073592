///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Author */

.author {
	@include vendor('display', 'flex');
	@include vendor('flex-direction', 'row');
	@include vendor('align-items', 'center');
	@include vendor('justify-content', 'flex-end');
	border-bottom: 0;
	font-family: _font(family-heading);
	font-size: 0.6em;
	font-weight: _font(weight-heading);
	letter-spacing: _font(kerning-heading);
	text-transform: uppercase;
	white-space: nowrap;

	.name {
		@include vendor('transition', 'border-bottom-color #{_duration(transition)} ease');
		border-bottom: dotted 1px _palette(border-alt);
		display: block;
		margin: 0 1.5em 0 0;
	}

	img {
		border-radius: 100%;
		display: block;
		width: 4em;
	}

	&:hover {
		.name {
			border-bottom-color: transparent;
		}
	}
}
