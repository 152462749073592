///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

#footer {
	.icons {
		color: _palette(fg-light);
	}

	.copyright {
		color: _palette(fg-light);
		font-family: _font(family-heading);
		font-size: 0.5em;
		font-weight: _font(weight-heading);
		letter-spacing: _font(kerning-heading);
		text-transform: uppercase;
	}

	body.single & {
		text-align: center;
	}
}
