// About page (/about)

.markdown {
	p {
		margin: auto;
	}

	h1 {
		font-size: 0.8em;
		margin-top: 3em;
	}
}
