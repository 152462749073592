///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

.icon {
	@include icon;
	border-bottom: none;
	position: relative;

	> .label {
		display: none;
	}

	&.prefix {
	}

	&.suffix {
		&:before {
			float: right;
		}
	}
}
