///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Form */

form {
	margin: 0 0 _size(element-margin) 0;

	&.search {
		@include icon;
		position: relative;

		&:before {
			color: _palette(fg-light);
			content: '\f002';
			display: block;
			height: _size(element-height);
			left: 0;
			line-height: _size(element-height);
			position: absolute;
			text-align: center;
			top: 0;
			width: 2.5em;
		}

		> input:first-child {
			padding-left: 2.5em;
		}
	}
}

label {
	color: _palette(fg-bold);
	display: block;
	font-size: 0.9em;
	font-weight: _font(weight-bold);
	margin: 0 0 (_size(element-margin) * 0.5) 0;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
select,
textarea {
	@include vendor('appearance', 'none');
	background: _palette(border-bg);
	border: none;
	border: solid 1px _palette(border);
	border-radius: 0;
	color: inherit;
	display: block;
	outline: 0;
	padding: 0 1em;
	text-decoration: none;
	width: 100%;

	&:invalid {
		box-shadow: none;
	}

	&:focus {
		border-color: _palette(accent);
		box-shadow: inset 0 0 0 1px _palette(accent);
	}
}

.select-wrapper {
	@include icon;
	display: block;
	position: relative;

	&:before {
		color: _palette(border);
		content: '\f078';
		display: block;
		height: _size(element-height);
		line-height: _size(element-height);
		pointer-events: none;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		width: _size(element-height);
	}

	select::-ms-expand {
		display: none;
	}
}

input[type='text'],
input[type='password'],
input[type='email'],
select {
	height: _size(element-height);
}

textarea {
	padding: 0.75em 1em;
}

input[type='checkbox'],
input[type='radio'] {
	@include vendor('appearance', 'none');
	display: block;
	float: left;
	margin-right: -2em;
	opacity: 0;
	width: 1em;
	z-index: -1;

	& + label {
		@include icon;
		color: _palette(fg);
		cursor: pointer;
		display: inline-block;
		font-size: 1em;
		font-weight: _font(weight);
		padding-left: (_size(element-height) * 0.6) + 0.75em;
		padding-right: 0.75em;
		position: relative;

		&:before {
			background: _palette(border-bg);
			border: solid 1px _palette(border);
			content: '';
			display: inline-block;
			height: (_size(element-height) * 0.6);
			left: 0;
			line-height: (_size(element-height) * 0.575);
			position: absolute;
			text-align: center;
			top: 0;
			width: (_size(element-height) * 0.6);
		}
	}

	&:checked + label {
		&:before {
			background: _palette(fg-bold);
			border-color: _palette(fg-bold);
			color: _palette(bg);
			content: '\f00c';
		}
	}

	&:focus + label {
		&:before {
			border-color: _palette(accent);
			box-shadow: 0 0 0 1px _palette(accent);
		}
	}
}

input[type='checkbox'] {
	& + label {
		&:before {
		}
	}
}

input[type='radio'] {
	& + label {
		&:before {
			border-radius: 100%;
		}
	}
}

::-webkit-input-placeholder {
	color: _palette(fg-light) !important;
	opacity: 1;
}

:-moz-placeholder {
	color: _palette(fg-light) !important;
	opacity: 1;
}

::-moz-placeholder {
	color: _palette(fg-light) !important;
	opacity: 1;
}

:-ms-input-placeholder {
	color: _palette(fg-light) !important;
	opacity: 1;
}

.formerize-placeholder {
	color: _palette(fg-light) !important;
	opacity: 1;
}
