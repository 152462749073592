///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Mini Post */
.cell-container {
	position: relative;

	.description {
		position: absolute;
		padding: 0;
		bottom: 0;
		border-top: solid 1px _palette(border);

		p {
			font-size: 0.9rem;
			margin: 0;
			padding: 1.25em;
			background-color: rgba(255, 255, 255, 0.7);
			color: #3c3b3b;
			font-family: 'Raleway', Helvetica, sans-serif;
			z-index: 1000;
			transition: all 0.1s;
			-webkit-transition: all 0.1s ease-in-out;
		}

		p:hover {
			background-color: rgba(255, 255, 255, 0.9);
		}
	}

	@media (max-width: 480px) {
		.description {
			position: inherit;
			padding: 0;
			bottom: inherit;
		}
	}
}

.mini-post {
	@include vendor('display', 'flex');
	@include vendor('flex-direction', 'column');
	background: _palette(bg);
	border: solid 1px _palette(border);
	margin: 0 0 _size(element-margin);

	.image {
		overflow: hidden;
		width: 100%;

		img {
			@include vendor('transition', 'transform #{_duration(transition)} ease-out');
			width: 100%;
		}

		&:hover {
			img {
				@include vendor('transform', 'scale(1.05)');
			}
		}
	}

	header {
		z-index: 2;
		@include padding(1.25em, 1.25em, (0, 3em, 0, 0));
		min-height: 4em;
		position: relative;
		@include vendor('flex-grow', '1');

		h3 {
			font-size: 0.7em;
		}

		.published {
			display: block;
			font-family: _font(family-heading);
			font-size: 0.6em;
			font-weight: _font(weight-heading);
			letter-spacing: _font(kerning-heading);
			margin: -0.625em 0 (_size(element-margin) * 0.85) 0;
			text-transform: uppercase;
		}

		.author {
			position: absolute;
			right: 2em;
			top: 2em;
		}
	}
}

.mini-posts {
	margin: 0 0 _size(element-margin);
	@include breakpoint(large) {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		width: calc(100% + #{_size(element-margin)});

		> * {
			margin: _size(element-margin) _size(element-margin) 0 0;
			width: calc(50% - #{_size(element-margin)});
		}

		> :nth-child(-n + 2) {
			margin-top: 0;
		}
	}
	@include breakpoint(xsmall) {
		display: block;
		width: 100%;

		> * {
			margin: 0 0 _size(element-margin);
			width: 100%;
		}
	}
}
